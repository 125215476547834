import React from "react";
import ContentLoader from "react-content-loader";

const MyLoader = () => (
  <ContentLoader
    height={240}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="10" y="0" rx="3" ry="3" width="350" height="45" />
    <rect x="10" y="60" rx="3" ry="3" width="350" height="45" />
    <rect x="10" y="120" rx="3" ry="3" width="350" height="45" />
    <rect x="10" y="180" rx="3" ry="3" width="350" height="45" />
  </ContentLoader>
);

export default MyLoader;
