import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { routesPath } from "./constants/Configs";
import Home from "./screens/Home";
import Subject from "./screens/Subject";
import Chapter from "./screens/Chapter";
import Question from "./screens/Question";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path={`${routesPath.subject}`} component={Subject} />
          <Route
            path={`${routesPath.chapter}/:subjectId`}
            component={Chapter}
          />
          <Route
            path={`${routesPath.question}/:chapterId`}
            component={Question}
          />
          <Route path={routesPath.home} component={Home} />
        </Switch>
      </div>
    </Router>
  );
}
