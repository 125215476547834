import instance from "./api";
import { getData, setData } from "./storage";
export async function getQuestions(chapter_id) {
  const key = `question-${chapter_id}`;
  const localQuestions = await getData(key);
  if (localQuestions !== null) {
    return Promise.resolve(localQuestions);
  }

  return instance.get(`/question/${chapter_id}`).then(resp => {
    setData(key, resp.data.questions);
    return resp.data.questions;
  });
}
