import React, { Component } from "react";
import {
  Navbar,
  NavbarBrand,
  Container,
  Row,
  Col,
  Jumbotron
} from "reactstrap";
import { Link } from "react-router-dom";
import MyLoader from "../components/MyLoader";
import GoBack from "../components/GoBack";
import { routesPath } from "../constants/Configs";
import { getSubjects } from "../network/subject";
class Subject extends Component {
  state = {
    isLoading: true,
    errorString: "",
    subjects: []
  };
  componentDidMount() {
    getSubjects()
      .then(subjects => {
        this.setState({ subjects, isLoading: false, errorString: "" });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          errorString: `Unable to get subjects: ${err.message}`
        });
      });
  }
  render() {
    return (
      <div>
        <Navbar color="inverse" light expand="md">
          <GoBack {...this.props} />
          <NavbarBrand>Subject</NavbarBrand>
        </Navbar>
        <Jumbotron className="mx-1">
          <Container>
            <Row className="mt-2">
              {this.state.isLoading ? (
                <Col className="text-center">
                  <MyLoader />
                </Col>
              ) : null}
              {this.state.errorString !== "" ? (
                <Col className="text-center">{this.state.errorString}</Col>
              ) : null}
            </Row>
            {this.state.subjects.map(subject => (
              <Row key={subject._id} className="mt-2">
                <Col className="text-center">
                  <Link
                    to={{
                      pathname: `${routesPath.chapter}/${subject._id}`,
                      state: { prevPath: routesPath.subject }
                    }}
                    className="form-control btn btn-info btn-large"
                  >
                    {subject.subject_name}
                  </Link>
                </Col>
              </Row>
            ))}
          </Container>
        </Jumbotron>
      </div>
    );
  }
}

export default Subject;
