import React, { Component } from "react";

import { Button } from "reactstrap";

export default class Option extends Component {
  state = {
    btnColor: "info"
  };
  clickHandler() {
    if (this.props.isCorrect) {
      this.setState({ btnColor: "success" });
    } else {
      this.setState({ btnColor: "danger" });
    }
  }
  render() {
    return (
      <Button
        className="form-control mt-1 text-left"
        style={{ height: "auto" }}
        color={this.state.btnColor}
        size="large"
        onClick={() => {
          this.clickHandler();
        }}
      >
        {this.props.qText}
      </Button>
    );
  }
}
