import instance from "./api";
import { getData, setData } from "./storage";

export async function getChapters(subject_id) {
  const keyName = `chapter-${subject_id}`;
  const localChapters = await getData(keyName);
  if (localChapters !== null) {
    return Promise.resolve(localChapters);
  }
  return instance.get(`/chapter/${subject_id}`).then(resp => {
    setData(keyName, resp.data.chapters);
    return resp.data.chapters;
  });
}
