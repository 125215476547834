import React, { Component } from "react";
import {
  Navbar,
  NavbarBrand,
  Container,
  Row,
  Col,
  Jumbotron
} from "reactstrap";

import { Link } from "react-router-dom";
import MyLoader from "../components/MyLoader";
import { routesPath } from "../constants/Configs";
import { getChapters } from "../network/chapter";
import GoBack from "../components/GoBack";
class Chapter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorString: "",
      chapters: []
    };
  }
  componentDidMount() {
    getChapters(this.props.match.params.subjectId)
      .then(chapters => {
        this.setState({ chapters, isLoading: false, errorString: "" });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          errorString: `Unable to get subjects: ${err.message}`
        });
      });
  }
  render() {
    return (
      <div>
        <Navbar color="inverse" light expand="md" className="text-center">
          <GoBack {...this.props} />
          <NavbarBrand>Chapter</NavbarBrand>
        </Navbar>
        <Jumbotron>
          <Container>
            <Row className="mt-2">
              {this.state.isLoading ? (
                <Col className="text-center">
                  <MyLoader />
                </Col>
              ) : null}
              {this.state.errorString !== "" ? (
                <Col className="text-center">{this.state.errorString}</Col>
              ) : null}
            </Row>
            {this.state.chapters.map(chapter => (
              <Row key={chapter._id} className="mt-2">
                <Col className="text-center">
                  <Link
                    to={{
                      pathname: `${routesPath.question}/${chapter._id}`,
                      state: {
                        prevPath: `${routesPath.subject}/${this.props.match.params.subjectId}`
                      }
                    }}
                    className="form-control btn btn-info btn-large"
                  >
                    {chapter.chapter_name}
                  </Link>
                </Col>
              </Row>
            ))}
          </Container>
        </Jumbotron>
      </div>
    );
  }
}

export default Chapter;
