import React, { Component } from "react";
import {
  Navbar,
  NavbarBrand,
  Container,
  Row,
  Col,
  Jumbotron
} from "reactstrap";

import GoBack from "../components/GoBack";
import MyLoader from "../components/MyLoader";
import MyShare from "../components/MyShare";
import Option from "../components/Option";
import { getQuestions } from "../network/question";
import { optionTitle } from "../constants/Configs";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorString: "",
      questions: [],
      prevPath: this.props.location.state.prevPath || "/"
    };
  }
  componentDidMount() {
    getQuestions(this.props.match.params.chapterId)
      .then(questions => {
        this.setState({ questions, isLoading: false, errorString: "" });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          errorString: `Unable to get subjects: ${err.message}`
        });
      });
  }
  render() {
    return (
      <div>
        <Navbar color="inverse" light expand="md">
          <GoBack {...this.props} />
          <NavbarBrand>Question</NavbarBrand>
        </Navbar>
        <Row className="mt-2">
          {this.state.isLoading ? (
            <Col className="text-center">
              <MyLoader />
            </Col>
          ) : null}
          {this.state.errorString !== "" ? (
            <Col>{this.state.errorString}</Col>
          ) : null}
        </Row>
        {this.state.questions.map((question, index) => (
          <Jumbotron key={question._id} className="mx-1">
            <Container>
              <Row>
                <Col className="align-items-stretch">
                  <p>
                    {index + 1}) {question.text}
                  </p>
                  {question.options.map((o, oIndex) => (
                    <Option
                      key={`${index}-${oIndex}`}
                      isCorrect={"" + question.answer === "" + oIndex}
                      qText={`${optionTitle[oIndex]}). ${o}`}
                    />
                  ))}
                  <div className="mt-1 float-right">
                    <MyShare question={question} />
                  </div>
                </Col>
              </Row>
            </Container>
          </Jumbotron>
        ))}
      </div>
    );
  }
}

export default Question;
