export const routesPath = {
  home: "/",
  subject: "/subject",
  chapter: "/chapter",
  question: "/question"
};

export const optionTitle = ["a", "b", "c", "d"];

//const d=b.split("").map(c=>c.charCodeAt(0)+1)
export const apiURL = [
  105,
  117,
  117,
  113,
  116,
  59,
  48,
  48,
  53,
  114,
  111,
  112,
  99,
  105,
  104,
  123,
  54,
  50,
  47,
  102,
  121,
  102,
  100,
  118,
  117,
  102,
  46,
  98,
  113,
  106,
  47,
  98,
  113,
  46,
  116,
  112,
  118,
  117,
  105,
  46,
  50,
  47,
  98,
  110,
  98,
  123,
  112,
  111,
  98,
  120,
  116,
  47,
  100,
  112,
  110,
  48,
  113
]
  .map(k => String.fromCharCode(k - 1))
  .join("");
