import React, { Component } from "react";
import {
  Navbar,
  NavbarBrand,
  Container,
  Row,
  Col,
  Jumbotron
} from "reactstrap";
import { Link } from "react-router-dom";
import { routesPath } from "../constants/Configs";
class Home extends Component {
  // componentDidMount() {
  //   loadLocalData();
  // }
  handleRoute(e) {
    e.preventDefault();
    this.props.history.push(routesPath.subject);
  }
  render() {
    return (
      <div>
        <Navbar color="inverse" light expand="md">
          <NavbarBrand href="/">Tet Practice Sets</NavbarBrand>
        </Navbar>
        <Jumbotron className="mx-1">
          <Container>
            <Row>
              <Col className="text-center">
                <h3>TET Exam Objective Type Question Practice Sets.</h3>
                <div>
                  <p>
                    Start online test with multiple choice questions (MCQ) of
                    Teacher Eligibility Test exams CTET, UPTET, JTET, DSSSB,
                    HTET, Rajasthan TET, & KVS. Attempt this practice sets to
                    score high in TET Exams.
                  </p>
                </div>
                <p>
                  <Link
                    to={{
                      pathname: `${routesPath.subject}`,
                      state: { prevPath: "/" }
                    }}
                    className="btn btn-info btn-large"
                  >
                    Start
                  </Link>
                </p>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </div>
    );
  }
}

export default Home;
