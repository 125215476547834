import React, { Component } from "react";
const backIcon = require("../images/back.svg");

export default class GoBack extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  goBack(e) {
    e.preventDefault();
    // console.log(this.props);
    this.props.history.goBack();
  }
  render() {
    return (
      <>
        <a href="/" onClick={this.goBack}>
          <img src={backIcon} alt="back" />
        </a>
      </>
    );
  }
}
