import React, { Component } from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { optionTitle } from "../constants/Configs";
export default class MyShare extends Component {
  render() {
    const shareTitle = `Q. ${this.props.question.text.replace(
      /\r|\n/g,
      ""
    )}\n\n${this.props.question.options
      .map((o, oIndex) => `${optionTitle[oIndex]}). ${o.replace(/\r|\n/g, "")}`)
      .join(`\n`)}\n\nअभ्यास के लिए यह ऐप डाउनलोड करें.`;

    return (
      <>
        <WhatsappShareButton
          className="ml-1 d-inline-block"
          url="https://bit.ly/2Oafa55"
          title={shareTitle}
        >
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <FacebookShareButton
          className="ml-1 d-inline-block"
          url="https://bit.ly/2Oafa55"
          quote={shareTitle}
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </>
    );
  }
}
