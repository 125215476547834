import instance from "./api";
import { getData, setData } from "./storage";
export async function getSubjects() {
  const localSubjects = await getData("subject");
  if (localSubjects !== null) {
    return Promise.resolve(localSubjects);
  }
  return instance.get("/subject").then(resp => {
    setData("subject", resp.data.subjects);
    return resp.data.subjects;
  });
}
